var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-card',[_c('b-card-header',[_c('h4',{staticClass:"mb-0"},[_vm._v("Thêm mới")]),_c('b-card-text',{staticClass:"font-medium-5 mb-0"},[_c('b-link',{attrs:{"to":"/content-page-list"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Trờ về ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Lưu")])],1)],1),_c('b-card-body',[_c('validation-observer',{ref:"formRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"4"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'title',"text":'Tiêu đề',"required":true}}),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"Tên"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'seoUrl',"text":'Seo Url',"required":true}}),_c('validation-provider',{attrs:{"name":"seoUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"seoUrl","state":errors.length > 0 ? false : null,"placeholder":"Seo Url"},model:{value:(_vm.input.seoUrl),callback:function ($$v) {_vm.$set(_vm.input, "seoUrl", $$v)},expression:"input.seoUrl"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'metaTitle',"text":'Tiêu đề Seo Meta'}}),_c('b-form-input',{attrs:{"id":"metaTitle","placeholder":"Tiêu đề Seo Meta"},model:{value:(_vm.input.metaTitle),callback:function ($$v) {_vm.$set(_vm.input, "metaTitle", $$v)},expression:"input.metaTitle"}})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'metaDescription',"text":'Mô tả Seo Meta'}}),_c('b-form-textarea',{attrs:{"id":"metaDescription","placeholder":"Mô tả Seo Meta","rows":"4"},model:{value:(_vm.input.metaDescription),callback:function ($$v) {_vm.$set(_vm.input, "metaDescription", $$v)},expression:"input.metaDescription"}})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"8"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'content',"text":'Nội dung',"required":true}}),_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('quill-editor',{attrs:{"id":"content"},model:{value:(_vm.input.content),callback:function ($$v) {_vm.$set(_vm.input, "content", $$v)},expression:"input.content"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }